import styled from 'styled-components';
import PageContainer from '../components/Wrappers';
import { useApp } from '../contexts/AppContext';
import { doHapticFeedback } from '../lib/utils';
import { useEffectOnce } from 'react-use';
import React, { useEffect, useState } from 'react';
import CaveCoins from '../components/pages/Cave/CaveCoins';
import { INFT } from '../lib/types';
import Row from '../components/Row';
import ToggleLabel from '../components/ToggleLabel';
import Toggle from 'react-toggle';
import CaveToggle from '../components/pages/Cave/CaveToggle';
import useLocalStorageState from 'use-local-storage-state';

const Container = styled.div.attrs({
  'data-tip': 'page-cave',
})`
  background: black;
  margin: -320px -40px;
  width: 1080px;
  height: calc(100vh / var(--scale));
  overflow: scroll;
`;

const CaveBackground = styled.div<{ img: string }>`
  background: url(${({ img }) => img}) center -20px no-repeat;
  background-size: cover;
  width: 960px;
  height: 1660px;
  padding: 40px 0;
  margin: 0 auto;
  position: relative;
  top: calc(100vh / var(--scale) / 2 - 960px);

  .poster {
    position: absolute;
    top: 430px;
    left: 140px;
    width: 150px;
  }

  &.basic .poster {
    left: 540px;
  }

  &.earth .poster {
    left: 160px;
    top: 438px;
    width: 135px;
  }

  .trophy {
    position: absolute;
    bottom: 829px;
    left: 371px;
  }

  .souvenir {
    position: absolute;
    bottom: 821px;
    left: 420px;
    width: 140px;
  }

  .desk {
    position: absolute;
    bottom: 515px;
    right: 72px;
    width: 630px;
  }

  &.basic .desk {
    bottom: 518px;
  }

  .gameMachine {
    position: absolute;
    bottom: 963px;
    left: 325px;
    height: 25;
  }

  &.basic .gameMachine {
    bottom: 954px;
  }

  .fireplace {
    position: absolute;
    right: 0;
    bottom: 15px;
    width: 470px;
  }

  .bed {
    position: absolute;
    bottom: 978px;
    right: 10px;
    width: 440px;
  }

  &.basic .bed {
    bottom: 969px;
  }

  .sofa {
    position: absolute;
    width: 650px;
    left: -70px;
    bottom: 20px;
  }

  .lighting {
    position: absolute;
    width: 940px;
    top: 0;
    left: 0;
  }

  .cave-fg {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
  }
`;

type IRarityMap = { [rarityKey: string]: number };
type IRarityMaps = { [collectionName: string]: IRarityMap };

const rarityMaps: IRarityMaps = {
  cave: {
    medieval_cave: 2,
    moonlight_cave: 3,
    earth_cave: 5,
  },
  trophy: {
    golden_trophy: 2,
    heros_trophy: 3,
    champions_trophy: 5,
  },
  fireplace: {
    tv: 2,
    hunter: 3,
    art: 5,
  },
  game_machine: {
    arcade: 2,
    lucky_wheel: 3,
    big_win: 5,
  },
  desk: {
    investor: 2,
    freelancer: 3,
    gamer: 5,
  },
  bed: {
    hammock: 2,
    egg: 3,
    double: 5,
  },
  sofa: {
    statue: 2,
    avantgarde: 3,
    posters: 5,
  },
  souvenir: {
    frog: 2,
    glassball: 3,
    kitty: 5,
  },
  poster: {
    meerminator: 2,
    double: 3,
    alone: 5,
  },
  lighting: {
    garland: 2,
    candles: 3,
    lamps: 5,
  }
};

function getRarity(url: string, rarityMap: IRarityMap) {
  const foundKey = Object.keys(rarityMap).find((key) => url.includes(key));
  return foundKey ? rarityMap[foundKey] : Number.MAX_SAFE_INTEGER;
}

const getRarestNftImage = (nfts: INFT[], collectionName: string) => {
  const items = nfts.filter((item) => item.nftCollection === collectionName && !!item.imageUrl);

  if (!items.length) return null;

  const map = rarityMaps[collectionName] || {};

  items.sort((a, b) => {
    const aRarity = getRarity(a.imageUrl ?? '', map);
    const bRarity = getRarity(b.imageUrl ?? '', map);
    return aRarity - bRarity;
  });

  return items[0]?.imageUrl || null;
};

export const Cave = () => {
  const { app } = useApp();
  const [rarity, setRarity] = useLocalStorageState('rarity', { defaultValue: false });

  const { nfts } = app;

  const basicCaveImage = 'https://cdn2.timesoul.com/nft/cave/img/basic_cave_small.webp';
  const caveImage = rarity
    ? getRarestNftImage(nfts, 'cave') || basicCaveImage
    : nfts.find((item) => item.nftCollection === 'cave' && !!item.imageUrl)?.imageUrl || basicCaveImage;
  const isBasicCave = basicCaveImage === caveImage;
  const isEarthCave =  caveImage.indexOf('earth_cave') > -1;
  const caveFg =
    caveImage === basicCaveImage
      ? 'https://cdn2.timesoul.com/nft/cave/img/basic_cave_small-fg2.webp'
      : `${caveImage.replace('.webp', '')}-fg.webp`;

  const trophyImage = rarity
    ? getRarestNftImage(nfts, 'trophy')
    : nfts.find((item) => item.nftCollection === 'trophy' && !!item.imageUrl)?.imageUrl;
  const fireplaceImage = rarity
    ? getRarestNftImage(nfts, 'fireplace')
    : nfts.find((item) => item.nftCollection === 'fireplace' && !!item.imageUrl)?.imageUrl;
  const gameMachineImage = rarity
    ? getRarestNftImage(nfts, 'game_machine')
    : nfts.find((item) => item.nftCollection === 'game_machine' && !!item.imageUrl)?.imageUrl;
  const deskImage = rarity
    ? getRarestNftImage(nfts, 'desk')
    : nfts.find((item) => item.nftCollection === 'desk' && !!item.imageUrl)?.imageUrl;
  const bedImage = rarity
    ? getRarestNftImage(nfts, 'bed')
    : nfts.find((item) => item.nftCollection === 'bed' && !!item.imageUrl)?.imageUrl;
  const sofaImage = rarity
    ? getRarestNftImage(nfts, 'sofa')
    : nfts.find((item) => item.nftCollection === 'sofa' && !!item.imageUrl)?.imageUrl;
  const souvenirImage = rarity
    ? getRarestNftImage(nfts, 'souvenir')
    : nfts.find((item) => item.nftCollection === 'souvenir' && !!item.imageUrl)?.imageUrl;
  const posterImage = rarity
    ? getRarestNftImage(nfts, 'poster')
    : nfts.find((item) => item.nftCollection === 'poster' && !!item.imageUrl)?.imageUrl;
  const lightingImage = rarity
    ? getRarestNftImage(nfts, 'lighting')
    : nfts.find((item) => item.nftCollection === 'lighting' && !!item.imageUrl)?.imageUrl;


  useEffect(() => {
    console.debug({ nfts });
  }, [nfts]);

  useEffectOnce(() => {
    doHapticFeedback();
  });

  const handleToggleChange = () => {
    setRarity((prevRarity) => !prevRarity);
  };

  return (
    <PageContainer>
      <Container>
        <CaveBackground className={isBasicCave ? 'basic' : isEarthCave ? 'earth' : ''} img={caveImage}>
          {posterImage && <img className="poster" alt="poster" src={posterImage} />}
          {trophyImage && <img className="trophy" alt="trophy" src={trophyImage} />}
          {fireplaceImage && <img className="fireplace" alt="fireplace" src={fireplaceImage} />}
          {gameMachineImage && <img className="gameMachine" alt="gameMachine" src={gameMachineImage} />}
          {deskImage && <img className="desk" alt="desk" src={deskImage} />}
          {bedImage && <img className="bed" alt="bed" src={bedImage} />}
          {sofaImage && <img className="sofa" alt="sofa" src={sofaImage} />}
          {souvenirImage && <img className="souvenir" alt="sofa" src={souvenirImage} />}
          {lightingImage && <img className="lighting" alt="lighting" src={lightingImage} />}
          <img className="cave-fg" alt="cave-fg" src={caveFg} />
          <CaveCoins />
          <CaveToggle defaultChecked={rarity} handleToggleChange={handleToggleChange} />
        </CaveBackground>
      </Container>
    </PageContainer>
  );
};
