import styled from 'styled-components';
import { IconCoin } from '../../Icons/IconCoin';
import { useTranslation } from 'react-i18next';
import { useApp } from '../../../contexts/AppContext';
import { speedByNFTs } from '../../../lib/farming/util';

const Container = styled.div.attrs({
  'data-tip': 'cave-coins-container',
})`
  * {
    opacity: 0.5;
  };
  display: flex;
  width: fit-content;
  background: #382a3f;
  border: 1.5px solid #a09da2;
  margin: 0 auto;

  border-radius: 90px;
  height: 55px;
  padding: 0 40px;
  gap: 40px;
  align-items: center;
  opacity: 70%;

  position: fixed;
  top: 150px;
  left: 0;
  right: 0;

  img {
    height: 50px;
  }

`;

const Separator = styled.div`
  border-left: 1.5px solid white;
  height: 80%;
`;

const Text = styled.div`
  color: #fff;
  font-size: 36px;
  display: flex;
  align-items: center;
  span {
    color: #faff00;
  }
`;

const CaveCoins = () => {
  const { t } = useTranslation();
  const { app } = useApp();

  const coinsFromCave = speedByNFTs(app);
  return (
    <Container>
      <img alt="coins" src="/img/cave/coins.webp" />
      <Separator />
      <Text>
        <span>{t('cave.coinsFromCave')}</span>&nbsp;{coinsFromCave}
        <IconCoin />/{t('system.seconds')}
      </Text>
    </Container>
  );
};

export default CaveCoins;
